import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import Button, { BUTTON_TYPES } from 'components/Button';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import { IconCloseBlue } from 'images';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeBackgroundColor,
  getThemeBrandedColor,
  getThemeButtonFollowingColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeFunctionalColor,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  getThemePlaygroundColor,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const Wrapper = styled.section<IColor>(({ background, ...props }) => ({
  background,
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: '100%',
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
}));

export const ScraperContainer = styled.div({
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  display: CSS_DISPLAY.FLEX,
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
  height: '480px',
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  [MEDIA_QUERIES.TABLET]: {
    width: '80%',
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    height: 'auto',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '100%',
  },
});

export const RequestWrapper = styled.div((props) => ({
  width: '360px',
  height: '100%',
  padding: '40px',
  background: getThemePlaygroundColor(props, COLOR_SHADE.SECONDARY),
  border: 'none',
  isolation: 'isolate',
  position: CSS_POSITION.RELATIVE,
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: '40px 20px',
  },
}));

export const ResponseWrapper = styled.div((props) => ({
  flexGrow: 1,
  overflow: 'hidden',
  height: '100%',
  background: getThemePlaygroundColor(props, COLOR_SHADE.PRIMARY),
  border: 'none',
  borderLeft: `1px solid ${getThemePlaygroundColor(props, COLOR_SHADE.DEEP)}`,
  position: CSS_POSITION.RELATIVE,
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
    borderLeft: 'none',
  },
}));

export const RequestTitle = styled.p((props) => ({
  fontFamily: getThemeFontFamily(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
  fontWeight: 500,
  color: getThemeMainColor(props),
  textAlign: TEXT_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
}));

export const RequestIconContainer = styled.div((props) => ({
  width: '100%',
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: '28px',
  gap: getThemeGutter(props, TSHIRT_SIZE.XS),

  'span.logo-icon svg': {
    height: '28px',
    width: 'auto',
  },
  'span.title-logo-icon svg': {
    height: '40px',
  },
}));

export const RequestFormContainer = styled.div({
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    maxWidth: '280px',
    margin: '0 auto',
  },
});

export const RequestConfigurationContainer = styled.div((props) => ({
  width: '100%',
  position: CSS_POSITION.RELATIVE,
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  color: getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  lineHeight: '12px',
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontWeight: 500,
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
  zIndex: 1,
  svg: {
    height: '12px',
    width: '10px',
    marginRight: '6px',
  },
  [DOM_STATE.HOVER]: {
    textDecoration: 'underline',
  },
}));

export const IconInfoContainer = styled.div({
  display: CSS_DISPLAY.FLEX,
  justifyContent: 'center',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: '41px',
  svg: {
    width: '100%',
    margin: '10px 0',
  },
  [DOM_STATE.HOVER]: {
    svg: {
      opacity: 0.5,
    },
  },
});

export const InfoPopup = styled.div<{ isRequestSent: boolean }>(
  ({ isRequestSent, ...props }) => ({
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    position: CSS_POSITION.ABSOLUTE,
    bottom: 47,
    background: getThemePlaygroundColor(props, COLOR_SHADE.GRAY),
    padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    p: {
      fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.SMALL),
      fontWeight: 400,
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
      color: getThemePlaygroundColor(props, COLOR_SHADE.LIGHT),
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      bottom: isRequestSent ? '47px' : '110px',
    },
  })
);

export const InfoTitle = styled.div((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
  fontFamily: getThemeFontFamily(props),
  fontWeight: 500,
  marginBottom: '6px',
  color: getThemeMainColor(props),
}));

export const FormComponent = styled.form({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: '100%',
  [MEDIA_QUERIES.TABLET]: {
    alignItems: CSS_ALIGN.CENTER,
  },
});

export const FormInputContainer = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  position: CSS_POSITION.RELATIVE,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  border: `1px solid ${getThemePlaygroundColor(props, COLOR_SHADE.ACCENT)}`,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  width: '280px',
  height: '42px',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
  background: getThemePlaygroundColor(props, COLOR_SHADE.PRIMARY),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '240px',
  },
}));

export const FormSelectTarget = styled.div<{
  valuesVisible: boolean;
  isLocationsSelect: boolean;
}>(({ valuesVisible, isLocationsSelect, ...props }) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  width: isLocationsSelect ? '52px' : '238px',
  border: 'none',
  background: getThemeBackgroundColor(props, COLOR_SHADE.SECONDARY),
  color: getThemeMainColor(props),
  borderLeft: `1px solid ${getThemePlaygroundColor(
    props,
    COLOR_SHADE.CHARCOAL
  )} `,
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  lineHeight: '20px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  paddingLeft: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
  margin: `8px ${getThemeGutter(props)} 8px 0`,
  position: CSS_POSITION.RELATIVE,
  svg: {
    width: '11px',
    height: '7px',
    transform: valuesVisible ? 'rotate(180deg)' : 'rotate(0deg)',
    path: {
      fill: getThemeMainColor(props),
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: isLocationsSelect ? '52px' : '188px',
  },
}));

export const FormSelectDropdownBox = styled.div<{ isVisible: boolean }>(
  ({ isVisible, ...props }) => ({
    display: isVisible ? CSS_DISPLAY.FLEX : CSS_DISPLAY.NONE,
    position: CSS_POSITION.ABSOLUTE,
    width: '280px',
    top: '48px',
    padding: '6px 0',
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    background: getThemePlaygroundColor(props, COLOR_SHADE.PRIMARY),
    border: `1px solid ${getThemePlaygroundColor(props, COLOR_SHADE.ACCENT)}`,
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    zIndex: 2,
    right: -1,
    span: {
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
      lineHeight: '20px',
      fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: '240px',
    },
  })
);

export const FormSelectDropdownValue = styled.div<{
  selected: boolean;
}>(({ selected, ...props }) => ({
  display: CSS_DISPLAY.FLEX,
  color: getThemeMainColor(props),
  padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  alignItems: CSS_ALIGN.CENTER,
  opacity: selected ? 0.2 : 1,
  'svg + span, span + span': {
    marginLeft: getThemeGutter(props),
  },
  svg: {
    height: 18,
    width: 18,
  },
  [DOM_STATE.HOVER]: {
    background: selected
      ? 'default'
      : getThemePlaygroundColor(props, COLOR_SHADE.INVISIBLE),
    cursor: selected
      ? 'default'
      : getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
  },
}));

export const FormSelectLocations = styled.select((props) => ({
  display: CSS_DISPLAY.BLOCK,
  width: '52px',
  border: 'none',
  background: getThemeBackgroundColor(props, COLOR_SHADE.SECONDARY),
  color: getThemeMainColor(props),
  borderLeft: `1px solid ${getThemePlaygroundColor(
    props,
    COLOR_SHADE.CHARCOAL
  )}`,
  paddingLeft: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
  margin: `8px ${getThemeGutter(props)} 8px 0`,
}));

export const FormInput = styled.input<{ disabledSubmitButton?: boolean }>(
  ({ disabledSubmitButton, ...props }) => ({
    display: CSS_DISPLAY.BLOCK,
    width: '204px',
    border: 'none',
    background: getThemeBackgroundColor(props, COLOR_SHADE.SECONDARY),
    color: '#fff',
    paddingLeft: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
    '::placeholder': {
      color: disabledSubmitButton
        ? getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)
        : getThemePlaygroundColor(props, COLOR_SHADE.INACTIVE),
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: '175px',
    },
  })
);

const FormSubmitButton = FormInput.withComponent('button');

export const FormButton = styled(FormSubmitButton)((props) => ({
  width: '280px',
  height: '42px',
  border: `1px solid ${getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY)}`,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  color: getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
  background: getThemeBackgroundColor(props, COLOR_SHADE.SECONDARY),
  cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
  zIndex: 1,
  [DOM_STATE.HOVER]: {
    border: `2px solid ${getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY)}`,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '240px',
  },
}));

export const RequestLanguagesontainer = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  padding: '24px 40px',
  borderBottom: `1px solid ${getThemePlaygroundColor(
    props,
    COLOR_SHADE.CHARCOAL
  )}`,
  p: {
    color: getThemeMainColor(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
    fontWeight: 500,
    fontFamily: getThemeFontFamily(props),
  },
  [MEDIA_QUERIES.TABLET]: {
    div: {
      display: CSS_DISPLAY.FLEX,
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    p: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    },
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: '24px 20px',
  },
}));

export const RequestButton = styled(Button)<{ isSelected: boolean }>(
  ({ isSelected, ...props }) => ({
    alignSelf: CSS_ALIGN.FLEX_START,
    margin: 0,
    marginLeft: getThemeGutter(props),
    minWidth: '80px',
    height: '26px',
    padding: 0,
    textAlign: TEXT_ALIGN.CENTER,
    border: isSelected
      ? '1px solid rgba(44, 150, 247, 0.2)'
      : `1px solid ${getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY)}`,
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    color: isSelected
      ? getThemeMainColor(props)
      : getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontWeight: 500,
    cursor: isSelected
      ? 'initial'
      : getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
    ':first-of-type': {
      marginLeft: 0,
    },
    [DOM_STATE.HOVER]: {
      border: isSelected
        ? '1px solid rgba(44, 150, 247, 0.2)'
        : `2px solid ${getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY)}`,
      color: isSelected
        ? getThemeMainColor(props)
        : getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
      margin: 0,
      marginLeft: getThemeGutter(props),
      padding: 0,
      ':first-of-type': {
        marginLeft: 0,
      },
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      minWidth: '70px',
    },
  })
);

export const StickyBannerContainer = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  padding: '21px 24px',
  background: getThemePlaygroundColor(props, COLOR_SHADE.BLACK),
  borderRadius: '0 0 12px 0',
  p: {
    color: getThemeMainColor(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    fontFamily: getThemeFontFamily(props),
    fontWeight: 500,
    margin: `0 ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
  },
  svg: {
    minWidth: '48px',
    height: '48px',
    borderRadius: '50%',
    background: getThemeNeutralColor(props, COLOR_SHADE.SECONDARY),
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    borderRadius: '0 0 12px 12px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    p: {
      textAlign: TEXT_ALIGN.CENTER,
    },
    a: {
      marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
      marginBottom: 0,
      width: 'auto',
      minWidth: '120px',
    },
  },
}));

export const StickyBannerIconContainer = styled.div((props) => ({
  minWidth: '48px',
  height: '48px',
  borderRadius: '50%',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const StickyBannerCta = styled(Cta)((props) => ({
  width: '110px',
  height: '36px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  padding: 0,
  margin: 0,
  [DOM_STATE.HOVER]: {
    padding: 0,
    margin: 0,
  },
  [MEDIA_QUERIES.TABLET]: {
    width: '110px',
  },
}));

export const ResponseResultsContainer = styled.div({
  width: '100%',
  height: '314px',
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  [MEDIA_QUERIES.TABLET]: {
    height: '400px',
  },
});

export const ResponseHeader = styled.div<{ isReponseColorWhite: boolean }>(
  ({ isReponseColorWhite, ...props }) => ({
    display: CSS_DISPLAY.FLEX,
    justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
    alignItems: CSS_ALIGN.CENTER,
    padding: `24px 26px ${getThemeInputPaddingShape(
      props,
      TSHIRT_SIZE.L
    )} 40px`,
    position: CSS_POSITION.RELATIVE,
    p: {
      color: isReponseColorWhite
        ? getThemeMainColor(props)
        : getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
      fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
      fontFamily: getThemeFontFamily(props),
    },
    svg: {
      position: CSS_POSITION.ABSOLUTE,
      right: 26,
      cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
      [DOM_STATE.HOVER]: {
        width: '31px',
        height: '31px',
        right: 24,
      },
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      padding: `24px 26px ${getThemeInputPaddingShape(
        props,
        TSHIRT_SIZE.L
      )} 20px`,
    },
  })
);

export const StyledCodeBlock = styled.div((props) => ({
  padding: '0 5px 0 40px',
  pre: {
    ...flexColContainerStyles,
    width: '100%',
    height: '251px',
    overflow: 'auto',
    overflowX: 'hidden',
    overflowWrap: 'break-word',
    scrollbarGutter: 'stable',
    position: CSS_POSITION.RELATIVE,
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    fontWeight: 400,
    span: {
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.MONOSPACE),
    },
    '.token': {
      color: `${getThemeMainColor(props)} !important`,
    },
    '.string-property': {
      color: `${getThemeFunctionalColor(
        props,
        COLOR_SHADE.SECONDARY
      )} !important`,
    },
    '.open_grepper_editor': {
      display: 'none !important',
    },
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(65, 83, 101, 1)',
      height: '120px',
    },
    '::-webkit-scrollbar-track': {
      marginRight: '10px',
    },
    [MEDIA_QUERIES.TABLET]: {
      height: '337px',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: '0 5px 0 20px',
  },
}));

export const ParametersContainer = styled.div((props) => ({
  width: '360px',
  height: '480px',
  display: CSS_DISPLAY.BLOCK,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  position: CSS_POSITION.ABSOLUTE,
  background: getThemePlaygroundColor(props, COLOR_SHADE.SECONDARY),
  borderRadius: '12px 0 0 12px',
  zIndex: 2,
  top: 0,
  left: 0,
  padding: '20px 6px 20px 20px',
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
    borderRadius: '12px 12px 0 0',
  },
}));

export const ParametersScrollContainer = styled.div((props) => ({
  overflow: 'auto',
  overflowX: 'hidden',
  overflowWrap: 'break-word',
  scrollbarGutter: 'stable',
  height: '100%',
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: 'rgba(65, 83, 101, 1)',
    height: '20px',
  },
  '::-webkit-scrollbar-track': {
    marginRight: '10px',
  },
  svg: {
    zIndex: 2,
    cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY)?.cursor,
    position: CSS_POSITION.ABSOLUTE,
    right: 20,
    fill: getThemePlaygroundColor(props, COLOR_SHADE.SECONDARY),
    [DOM_STATE.HOVER]: {
      width: '31px',
      height: '31px',
      right: 19,
    },
  },
}));

export const ParametersHeader = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: '100%',
  paddingBottom: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  paddingRight: '8px',
  borderBottom: `1px solid ${getThemePlaygroundColor(props, COLOR_SHADE.DEEP)}`,
  p: {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    fontWeight: 400,
    color: getThemePlaygroundColor(props, COLOR_SHADE.OPTIONAL),
  },
}));

export const ParametersTitle = styled.div((props) => ({
  color: getThemeMainColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H5),
  fontWeight: 500,
  fontFamily: getThemeFontFamily(props),
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  marginBottom: getThemeGutter(props),
  position: CSS_POSITION.RELATIVE,
}));

export const ParametersContent = styled.div({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: '100%',
  paddingRight: '8px',
});

export const ParametersItem = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: '100%',
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)} 0`,
  borderBottom: `1px solid ${getThemePlaygroundColor(props, COLOR_SHADE.DEEP)}`,
  p: {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    fontWeight: 400,
    color: getThemeMainColor(props),
  },
}));

export const ParametersItemTitle = styled.div((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontWeight: 500,
  color: getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
  marginBottom: '6px',
}));

const RequestWindowWrapper = styled.div({});

export const StyledLoaderWrapper = styled.div((props) => ({
  height: '390px',
  width: '100%',
  display: CSS_DISPLAY.FLEX,
  flexDirection: 'column',
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  gap: getThemeGutter(props, TSHIRT_SIZE.XS),

  p: {
    color: getThemeMainColor(props, MAIN_COLOR.WHITE),
  },
}));

const colorOne = 'rgb(44,150,247)';
const colorTwo = 'rgb(44,150,247, 0.2)';

const loader = keyframes({
  '0%': {
    background: colorOne,
    boxShadow: ` -48px 0 ${colorOne}, 24px 0 ${colorTwo}, -24px 0 ${colorOne}, 24px 0 ${colorTwo}`,
  },
  '25%': {
    background: colorTwo,
    boxShadow: ` -48px 0 ${colorTwo}, 24px 0 ${colorOne}, -24px 0 ${colorTwo}, 24px 0 ${colorOne}`,
  },
  '50%': {
    background: colorOne,
    boxShadow: `-48px 0 ${colorTwo}, 24px 0 ${colorTwo}, -24px 0 ${colorTwo}, 24px 0 ${colorTwo}`,
  },
  '75%': {
    background: colorTwo,
    boxShadow: `-48px 0 ${colorTwo}, 24px 0 ${colorOne}, -24px 0 ${colorTwo}, 24px 0 ${colorOne}`,
  },
  '100%': {
    background: colorOne,
    boxShadow: ` -48px 0 ${colorTwo}, 24px 0 ${colorTwo}, -24px 0 ${colorOne}, 24px 0 ${colorTwo}`,
  },
});

export const LoaderContainer = styled.div({
  display: CSS_DISPLAY.BLOCK,
  width: '12px',
  height: '6px',
  position: 'relative',
  background: colorTwo,
  boxShadow: `-24px 0 ${colorOne}, 24px 0 ${colorTwo}`,
  boxSizing: 'border-box',
  marginRight: '2px',
  borderRadius: '12px',
  animation: `${loader} 1s linear infinite`,
  left: 12,
});

export const Line = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  overflowX: 'clip',
  gap: getThemeInputPaddingShape(props, TSHIRT_SIZE.XS),
  paddingRight: getThemeInputPaddingShape(props, TSHIRT_SIZE.XS),
}));

export const LineNo = styled.span((props) => ({
  flexShrink: 0,
  flexBasis: '40px',
  textAlign: TEXT_ALIGN.LEFT,
  userSelect: 'none',
  opacity: 0.5,
  color: `${getThemePlaygroundColor(props, COLOR_SHADE.INACTIVE)} !important`,
}));

export const LineContent = styled.span({
  width: 'calc(100% - 40px)',
  display: 'inline-flex',
  flexWrap: 'wrap',
});

export const Modal = styled.div({
  position: CSS_POSITION.ABSOLUTE,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export const HideableCloseIcon = hideable(IconCloseBlue);
export const HideableRequestWindowWrapper = hideable(RequestWindowWrapper);
